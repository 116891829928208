// Home -->

AcTransitMobi.home = function (params) {
    var tileViewData = [];
    $(".layout-content").removeClass("has-navbar");
    $("#layout-footer").hide();
    $(".view - footer").hide();
        
    //$(window).scroll(false);

    

    linkOpenNewTabforRealTime = function (data) {
        window.utils.triggerGA('/index.html#RealTimeDepartures', '#RealTimeDepartures');
        window.open('https://www.nextbus.com/#!/actransit', '_blank');
        //window.open('http://mobile.usablenet.com/mt/transit.511.org/schedules/realtimedepartures.aspx', '_blank');
    }
    //linkOpenNewTabforTripPlanner = function (data) {
    //    window.utils.triggerGA('/index.html#TripPlanner', '#TripPlanner');
    //    window.open('http://mobile.usablenet.com/mt/tripplanner.transit.511.org/mtc/XSLT_TRIP_REQUEST2?language=en&un_jtt_v_tab=newsearch', '_blank');
    //}
    linkOpenNewTabforeNews = function (data) {
        window.utils.triggerGA('/index.html#eNews', '#eNews');
        window.open('http://service.govdelivery.com/service/multi_subscribe.html?code=ACTRANSIT', '_blank');
    }
    linkOpenNewTabforeFullSite = function (data) {
        window.utils.triggerGA('/index.html#FullSite', '#FullSite');
        window.open(mainDomain, '_blank');
    }
    linkOpenNewTabfor511Org = function (data) {
        window.utils.triggerGA('/index.html#511', '#511');
        window.open('https://m.511.org', '_blank');
    }
    linkOpenNewTabforMobileApps = function (data) {
        window.utils.triggerGA('/index.html#MobileAppsTab', '#MobileAppsTab');
        window.open(mainDomain + '/rider-info/mobile-apps/ ', '_blank');
    }
    linkOpenNewTabforGoogleTransit = function (data) {
        window.utils.triggerGA('/index.html#GoogleTransit', '#GoogleTransit');
        window.open('https://www.google.com/maps?ie=UTF8&f=d&dirflg=rB&hl=en', '_blank');
    }
    linkOpenNewTabforKPI = function (data) {
        window.utils.triggerGA('/index.html#KPI', '#KPI');
        window.open('http://kpi.actransit.org/', '_blank');
    }
    linkOpenNewTabforPublicMeetings = function (data) {
        window.utils.triggerGA('/index.html#PublicMeetings', '#PublicMeetings');
        window.open('https://actransit.legistar.com/Calendar.aspx', '_blank');
    }
    refreshColumnSettings = function () {
        var width = $(window).width();

        if (width >= 0 && width <= 128) {
        }
        else if (width >= 129 && width <= 256) {
            $('.HomeButtonRow').removeClass('HomeButtonRow').addClass('HomeButtonSmaller');
            $('.HomeButtonSmall').removeClass('HomeButtonSmall').addClass('HomeButtonSmaller');
            $('.HomeButtonLarge').removeClass('HomeButtonLarge').addClass('HomeButtonSmaller');
            $('.HomeButtonNormal').removeClass('HomeButtonNormal').addClass('HomeButtonSmaller');
        }
        else if (width >= 257 && width <= 384) {
            $('.HomeButtonRow').removeClass('HomeButtonRow').addClass('HomeButtonSmall');
            $('.HomeButtonSmaller').removeClass('HomeButtonSmaller').addClass('HomeButtonSmall');
            $('.HomeButtonLarge').removeClass('HomeButtonLarge').addClass('HomeButtonSmall');
            $('.HomeButtonNormal').removeClass('HomeButtonNormal').addClass('HomeButtonSmall');
        }
        else if (width >= 385 && width <= 640) {
            $('.HomeButtonRow').removeClass('HomeButtonRow').addClass('HomeButtonNormal');
            $('.HomeButtonSmaller').removeClass('HomeButtonSmaller').addClass('HomeButtonNormal');
            $('.HomeButtonSmall').removeClass('HomeButtonSmall').addClass('HomeButtonNormal');
            $('.HomeButtonLarge').removeClass('HomeButtonLarge').addClass('HomeButtonNormal');
        }
        else if (width > 640) {
            $('.HomeButtonRow').removeClass('HomeButtonRow').addClass('HomeButtonLarge');
            $('.HomeButtonSmaller').removeClass('HomeButtonSmaller').addClass('HomeButtonLarge');
            $('.HomeButtonSmall').removeClass('HomeButtonSmall').addClass('HomeButtonLarge');
            $('.HomeButtonNormal').removeClass('HomeButtonNormal').addClass('HomeButtonLarge');
        }

        var device = DevExpress.devices.current();
        switch (device.platform) {
            case 'android':
                $(".home-sprite h2").css("left", "3px");
                break;
            case 'ios':
                $(".home-sprite h2").css("transform", "translateX(" + (device.version[0] < 5 ? -3 : -4) + "px)");
                break;
            case 'tizen':
                $(".home-sprite h2").css("left","4px");
                break;
            case 'win8':
                $(".home-sprite h2").css("left","1px");
                break;
            case 'generic':
                $(".home-sprite h2").css("left","2px");
                break;
        }
    }
    scrollToTopHome = function () {
        var aTag = $("#homeScroller");
        if (aTag.length != 1) return;
        $('#homeScroller').dxScrollView("instance").scrollTo(aTag.position().top);
    }
    var viewModel = {
        viewShown: function (args) {
            window.utils.triggerGA('/index.html#home', '#home');
            DeepLink.getInstance().Reset();

            $(document).ready(function () {
                viewModel.firstPassPopup();
                refreshColumnSettings();
                //scrollToTopHome();
                $(window).resize(function () {
                    refreshColumnSettings();
                });
            });

        },
        Year: ko.observable(new Date().getFullYear()),
        firstPassPopup: function() {
            var viewCount = "0";
            if (typeof window.localStorage !== 'undefined') {
                if (!window.localStorage.getItem("viewCount"))
                    window.localStorage.setItem("viewCount", 0);
                viewCount = window.localStorage.getItem("viewCount");
                viewCount++;
                window.localStorage.setItem("viewCount", viewCount);
            }
            var deviceType = DevExpress.devices.real().deviceType;
            deviceType = "anything";
            if ((viewCount == "1") && (deviceType !== "desktop")) {
                //$('#navbar-popup').dxPopup("instance").show();
                $('.dx-popup-title').css("background-color", "#e2e2e2");
            }
                
        }
    }
    return viewModel;
};

// TransbayService -->

AcTransitMobi.TransbayService = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        Content: ko.observable(null),

        url: service + 'article/transbay-service',

        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.Content(utils.fixContent(data.Content.replace(/<a href=".*?"><img.*?\/><\/a>\r\n/img, "")));
                $("#TransbayServiceContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorTransbayService(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#TransbayServiceContent", scrollTopElement: "#TransbayServiceScrollView", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopTransbayService();
            });
        },
        scrollToAnchorTransbayService: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#TransbayServiceScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopTransbayService: function scrollToTopTransbayService() {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#TransbayServiceScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html#TransbayService', '#TransbayService');
            viewModel.load();
        }
    };

    return viewModel;
};

// AllLineDescription-->

AcTransitMobi.AllLineDescription = function (params) {
    var viewModel = {
        initialized: function (args) {
        },
        //DataSource for AllLines
        AllLineDescription: [
          { key: 'Local', title: 'Local Lines' },
          { key: 'Transbay', title: 'Transbay Lines' },
          { key: 'All Nighter', title: 'All Nighter Lines' },
          { key: 'Service to Schools', title: 'Service to Schools Lines' },
          { key: 'Early Bird', title: 'Early Bird Lines' }],

    };

    return viewModel;
};

// LocalLine -->

AcTransitMobi.LocalLine = function (params) {
    //Bind Data in Array 
    DynamicObservable = function (data) {
        try {
            if (data.length != 0) {
                $("#LocalLineDiv").show();
                $("#LocalLineHeaderDiv").show();
                $("#errorMessageDiv").hide();
                AllLineValue.removeAll();
                for (var key = 0; key < data.length; key++) {
                    AllLineValue.push({
                        LineValue: data[key].LineValue,
                        description: utils.htmlDecode(data[key].Description),
                        MapUrl: UrlToAction(data[key].MapUrl, "#LineMap"),
                        ScheduleUrl: UrlToAction(data[key].ScheduleUrl, "#LineSchedules")
                    });
                }
            }
            else {
                errorMessageText = errorMessage;
                $("#LocalLineDiv").hide();
                $("#LocalLineHeaderDiv").hide();
                $("#errorMessageDiv").show();
            }
        }
        catch (err) {
        }

    };

    title = ko.observable(params.id);
    AllLineValue = ko.observableArray([]);
    errorMessageText = ko.observable(null);
    window.utils.triggerGA('/index.html#LocalLine', '#LocalLine');
    $.ajax({
        cache: false,
        type: "GET",
        async: false,
        url: service + 'lines/info',
        dataType: "json",
        success: ajaxCallSucceed,
        error: ajaxCallFailed,
    });

    function ajaxCallSucceed(response) {
        var newItems;
        switch (params.name) {
            case 'Local':
                newItems = response.LinesByCategory.Local;
                DynamicObservable(newItems);
                break;
            case 'Transbay':
                newItems = response.LinesByCategory.Transbay;
                DynamicObservable(newItems);
                break;
            case 'All Nighter':
                newItems = response.LinesByCategory['All Nighter'];
                DynamicObservable(newItems);
                break;
            case 'Service to Schools':
                newItems = response.LinesByCategory["Service to Schools"];
                DynamicObservable(newItems);
                break;
            case 'Early Bird':
                newItems = response.LinesByCategory['Early Bird'];
                DynamicObservable(newItems);
                break;
        }

        window.setTimeout(function () {
            $(".dx-button").css("float", "none").css("margin", "5px");
            $(".dx-button .dx-button-content").css("width", "auto");
        }, 10);
    }

    function UrlToAction(url, targetUri) {
        var re = /maps_line=(.*?)&/img;
        var match = re.exec(url);
        if ((!match) || (match.length != 2)) {
            re = /#PickLineInfo\?name=(.*)/img;
            match = re.exec(url);
        }
        if ((match) && (match.length == 2)) {
            return targetUri + '/' + params.name + '?name=' + match[1] + '';
        }
        return url;
    }

    function ajaxCallFailed(error) {
        errorMessageText = errorMessage;
        $("#LocalLineDiv").hide();
        $("#LocalLineHeaderDiv").hide();
        $("#errorMessageDiv").show();

    }
    OpenLinks = function (data) {
        Go(data.MapUrl);
    }
    OpenSchedulesLinks = function (data) {
        Go(data.ScheduleUrl);
    }
    Go = function(url) {
        try {
            (new DevExpress.framework.dxCommand({ action: url })).execute();
        } catch (e) { }
    }

    //ViewModel......
    var viewModel = function () {
        viewShown = function (args) {
        };

    };
    return viewModel;
};

// BusStopsByLine -->

AcTransitMobi.BusStopsByLine = function (params) {
    //View Model ....
    localdata = ko.observableArray([]);
    var viewModel = {
        errorMessageText: ko.observable(errorMessage),
        //Bind Data in DataSource ....
        AllLinesdataSource: new DevExpress.data.DataSource({
            load: function (loadOptions) {
                window.utils.triggerGA('/index.html#BusStopsByLine', '#BusStopsByLine');
                var jData = AcTransitMobi.db.AllLineDescription;
                return jData;

            },
            map: function (item) {
                if (item != null) {
                    $("#BusstopsDiv").show();
                    $("#errorMessageDiv").hide();
                    return {
                        Value: item.KeyValue.Value,
                        id: item.KeyValue.Value,
                    };
                }
                else {
                    $("#BusstopsDiv").hide();
                    $("#errorMessageDiv").show();
                }

            }
        }),
        viewShown: function (args) {
        },
    };
    return viewModel;
};

// BusStopsInCategory -->

AcTransitMobi.BusStopsInCategory = function (params) {
    AllLineValue = ko.observableArray([]);
    errorMessageText = ko.observable(null);
    titleText = ko.observable(null);
    titleText = params.id + ' ' + 'Lines';
    DynamicObservable = function (data) {
        try {
            if (data.length != 0) {
                AllLineValue.removeAll();
                for (var key = 0; key < data.length; key++) {
                    AllLineValue.push({ LineValue: data[key].LineValue, PostName: data[key].PostName });
                }
            }
            else {
                errorMessageText = errorMessage;
            }
        }
        catch (err) {
        }
    };
    window.utils.triggerGA('/index.html#BusStopsInCategory', '#BusStopsInCategory');

    $.ajax({
        cache: false,
        type: "GET",
        async: false,
        url: service + 'line/stops',
        dataType: "json",
        success: ajaxCallSucceed,
        error: ajaxCallFailed,
    });

    function ajaxCallSucceed(response) {
        if (response != null) {
            $("#errorMessageDiv").hide();
            switch (params.id) {
                case 'Local':
                    newItems = response.LinesByCategory.Local;
                    DynamicObservable(newItems);
                    break;
                case 'Transbay':
                    newItems = response.LinesByCategory.Transbay;
                    DynamicObservable(newItems);
                    break;
                case 'All Nighter':
                    newItems = response.LinesByCategory['All Nighter'];
                    DynamicObservable(newItems);
                    break;
                case 'Service to Schools':
                    newItems = response.LinesByCategory["Service to Schools"];
                    DynamicObservable(newItems);
                    break;
                case 'Early Bird':
                    newItems = response.LinesByCategory['Early Bird'];
                    DynamicObservable(newItems);
                    break;
                default:
                    $("#errorMessageDiv").show();
                    errorMessageText: errorMessage;
            }
            window.setTimeout(function () {
                var dev = DevExpress.devices.current();
                if (dev.ios && (dev.version[0] <= 3 || dev.version[0] >= 5)) {
                    $(".busStopButton .dx-button-content .dx-button-text").css("color", "white");
                    $(".busStopButton .dx-button-content").css("background-color", "dimgray");
                }
            }, 10);
        }
        else {
            $("#errorMessageDiv").show();
            errorMessageText: errorMessage;
        }
    }
    function ajaxCallFailed(error) {
        $("#errorMessageDiv").show();
        errorMessageText: errorMessage;
    }
    openLineStopView = function (data) {
        AcTransitMobi.app.navigate({ view: "LineStops", id: data.model.PostName, name: data.model.LineValue });
    }
    var viewModel = {
        //  Put the binding properties here
        viewShown: function (args) {
        },
    };

    return viewModel;
};

// LineStops -->

AcTransitMobi.LineStops = function (params) {
    var count = 0;

    var sview = '#LineStopScrollView';
    scrollToAnchorLineStop = function (lineId) {
        var aTag = $("span[id='" + lineId + "']");
        var position = aTag.position();
        $(sview).dxScrollView("instance").scrollTo(position.top);
    };

    scrollActionFunction = function (da) {
        if (da.scrollOffset.top >= 15) {
            $("#totopDiv").show();
        }
        else {
            $("#totopDiv").hide();
        }
    };

    scrollToTop = function () {
        var aTag = $("span[id='Top']");
        var position = aTag.position();
        $(sview).dxScrollView("instance").scrollTo(position.top);
    }

    id = ko.observable(null);
    title = ko.observable(null);
    Content = ko.observable(null);
    errorMessageText = ko.observable(null);
    var id = params.id;
    window.utils.triggerGA('/index.html#LineStops', '#LineStops');
    $.ajax({
        cache: false,
        type: "GET",
        async: false,
        url: service + 'line/stop/' + params.id + '/' + params.name,
        dataType: "json",
        success: ajaxCallSucceed,
        error: ajaxCallFailed,

    });

    function ajaxCallSucceed(response) {
        if (response != null) {
            id = response.Key;
            title = "Line Stops";
            LineStopContent = response.Content.toString().replace('table', 'table id="firstTableCss"').replace('\n', '<br/><br/>');
            var replaceFirstContent = /<a href='#/gi;
            subContent = LineStopContent.replace(replaceFirstContent, "<a onclick=scrollToAnchorLineStop('");//
            var replacelastContent = /'>/gi;
            Content = subContent.replace(replacelastContent, "')>");

            $("#errorMessageDiv").hide();
            $("#LineStopScrollView").show();
        }
        else {
            errorMessageText = errorMessage;
            $("#scrollView").hide();
            $("#errorMessageDiv").show();
        }
    }
    function ajaxCallFailed(error) {
        errorMessageText = errorMessage;
        $("#scrollView").hide();
        $("#errorMessageDiv").show();
    }

    //View Model....
    var viewModel = function () {
        viewShown = function (args) {
        };
    };

    return viewModel;
};

// ContactUs -->

AcTransitMobi.ContactUs = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        Content: ko.observable(null),
        url: service + 'article/contact-us',
        moveClass:null,
        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.Content(utils.fixContent(data.Content));
                self.fixHtmlHashAnchors();
                DeepLink.getInstance().CatchClick({ contentElement: "#ContactUsContent", scrollTopElement: "#ContactUsScrollView", initialUrl: self.url, isPanelVisible: self.loadPanelVisible, catchClickReady: self.fixHtmlHashAnchors, onLoaded: self.ajaxLoaded });
                self.scrollToTopContactUs();
                self.ajaxLoaded(false);
            });
        },
        ajaxLoaded: function (initIfExist) {
            var self = viewModel;
            var ul = $("ul:first-child");
            initIfExist = typeof (initIfExist) === 'undefined' ? true : initIfExist;
            if (ul.length != 0) {
                if (initIfExist || self.moveClass == null) {
                    self.moveClass = new ACTransit.MenuButton(ul);
                    self.moveClass.undo();
                    self.moveClass.moveToMenu();
                }
            }
            self.loadPanelVisible(false);
            $("#ContactUsContent").css("padding-left", "4px");
        },
        fixHtmlHashAnchors: function () {
            $("#ContactUsContent a[href^='#']").each(function (index, element) {
                element.id = element.href.split('#')[1]; // element.id = anything after #
            }).bind("click", function (eventObject) {
                viewModel.scrollToAnchorContactUs(eventObject.currentTarget.id); // element.id
            }).removeAttr("href");
        },
        scrollToAnchorContactUs: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#ContactUsScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },
        scrollToTopContactUs: function scrollToTopMobileApps() {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#ContactUsScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },
        viewShown: function () {
            window.utils.triggerGA('/index.html#ContactUs', '#ContactUs');
            viewModel.load();
        }
    };

    return viewModel;
};

// FLEX -->

AcTransitMobi.FLEX = function (params) {
    var viewModel = {
        viewShown: function () {
            window.utils.triggerGA('/index.html#FLEX', '#FLEX');
        }
    };
    return viewModel;
};

// Feedback -->

AcTransitMobi.Feedback = function (params) {
    var viewModel = {
        model: {
            ActionEnum: 1,
            Contact: {
                FirstName: ko.observable('').extend({ required: true, minLength: 2, maxLength: 30 }),
                LastName: ko.observable('').extend({ required: true, minLength: 2, maxLength: 30 }),
                MobilePhone: ko.observable(''),
                EmailAddress: ko.observable('').extend({ required: true, email: true, minLength: 6, maxLength: 50 }),
            },
            Incident: {
                DateTime: '',
                Vehicle: ko.observable('').extend({ pattern: { params: "^\\d{1,4}$", message: 'Maximum 4 numbers.' } }),
                Line: ko.observable('').extend({
                    pattern: { params: "^[a-zA-Z0-9]{1,4}$", message: 'Maximum 4 numbers or letters.' }
                }),
                Destination: ko.observable('').extend({ maxLength: 40 }),
                Location: ko.observable('').extend({ maxLength: 100 }),
            },
            Employee: {
                Badge: ko.observable('').extend({
                    required: { onlyIf: function() { return false; }, message: "Maximum 5 numbers." }
                }),
                Description: ko.observable('').extend({ maxLength: 1024 }),
            },
            LostItem: {
                Category: ko.observable(''),
                Type: ko.observable(''),
            },
            File: resetFile(),
            Comments: ko.observable(null).extend({ required: true, minLength: 10, maxLength: 8000 }),
            RequestResponse: ko.observable(false),
            ResponseCriteria: {
                Via: ko.observable(0)
            }
        },
        toggleModelRequirements: function () {
            var m = viewModel.model;
            if (viewModel.FeedbackType() == 'busshelter') {
                if (m.Contact.FirstName() == '')
                    m.Contact.FirstName('na');
                if (m.Contact.LastName() == '')
                    m.Contact.LastName('na');
                if (m.Contact.EmailAddress() == '')
                    m.Contact.EmailAddress('na@email.com');
                if (m.Incident.Destination() == '')
                  m.Incident.Destination('na');
            } else {
                m.Incident.Destination('');
                viewModel.LoadStorage();
            }
        },
        resetModel: function() {
            var m = viewModel.model;
            m.ActionEnum = 1;
            m.Contact.FirstName('');
            m.Contact.LastName('');
            m.Contact.MobilePhone('');
            m.Contact.EmailAddress('');
            m.Incident.DateTime = '';
            m.Incident.Vehicle('');
            m.Incident.Line('');
            m.Incident.Destination('');
            m.Incident.Location('');
            m.Employee.Badge('');
            m.Employee.Description('');
            m.LostItem.Category('');
            m.LostItem.Type('');
            m.File.Filename = '';
            m.File.Filesize = 0;
            m.File.ContentType = '';
            m.File.Base64Data = '';
            m.Comments('');
            m.RequestResponse(false);
            m.ResponseCriteria.Via(0);
            viewModel.ClearErrors();
        },
        Reset: function() {
            viewModel.resetModel();
        },
        LocationUpdated: function (e) {
            var locationValue = false;
            if (typeof e == "undefined" || !e.jQueryEvent) {
                locationValue = true;
            } else {
                var stopid = e.jQueryEvent.currentTarget.value;
                if (stopid.length != 5 || isNaN(stopid)) {
                    locationValue = true;
                }
                viewModel.model.Incident.Location(stopid);
            }
            viewModel.LocationErrorMessageText('');
            var locationMsg = viewModel.validateLocation().message;
            if (locationMsg != null) {
                if (viewModel.validateLocation().key === "location")
                    viewModel.LocationErrorMessageText(locationMsg);
            } else
                viewModel.LocationErrorMessageText('');

            if (locationValue === true)
                return;

            $.ajax({
                url: service + 'schedule/stop511/' + stopid,
                contentType: "text/plain; charset=utf-8",
            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log("error: " + errorThrown);
            }).done(function (data) {
                var place = data.Place.PlaceDescription || data.StopDescription;
                if (typeof place === "string" && place.length > 1) {
                    viewModel.model.Incident.Location(place + " (" + stopid + ")");
                    //viewModel.HideMap();
                }
            });
        },
        view: function () { return $('#FeedbackScrollView').dxScrollView("instance"); },
        dateValue: ko.observable(''),
        timeValue: ko.observable(''),
        isValidDateTime: function (target) {
            var dt = (typeof target === "function") ? new Date(target()) : target;
            return (Object.prototype.toString.call(dt) === "[object Date]" && !isNaN(dt.getTime()));
        },
        SetupDateValue: function () {
            if (!viewModel.isValidDateTime(viewModel.dateValue()))
                viewModel.dateValue('');
        },
        SetupTimeValue: function () {
            if (!viewModel.isValidDateTime(viewModel.timeValue()))
                viewModel.timeValue('');
        },
        getDateValue: function () {
            return viewModel.dateValue() != '' ? viewModel.dateValue().toISOString() : '';
        },
        resetDateTime: function () {
            viewModel.dateValue('');
        },
        validateDateValue: function () {
            switch (viewModel.FeedbackType()) {
                case "incident":
                case "lostfound":
                    if (!viewModel.isValidDateTime(viewModel.dateValue)) {
                        return { key: "date", message: "Please enter date and time." }
                    } else if (viewModel.dateValue().getTime() > new Date().getTime()) {
                        return { key: "future", message: "Date and Time cannot be in the future." }
                    } else if (viewModel.timeValue() == null || viewModel.timeValue().length === 0) {
                        return { key: "time", message: "Please enter time." }
                    } 
                    break;
                case "ask":
                case "suggestion":
                    break;
            }
            return { key: "ok", message: null };
        },
        validateLocation: function() {
            if (viewModel.Location() === "") 
                return { key: "location", message: "Please enter location." }

            return { key: "ok", message: null }
        },
        validateDestination: function() {
            if (viewModel.model.Incident.Destination() === "")
                return { key: "destination", message: "This field is required." }

            return { key: "ok", message: null }
        },
        validateCategory: function () {
            if (viewModel.model.LostItem.Category() === "")
                return { key: "category", message: "This field is required." }

            return { key: "ok", message: null }
        },
        validateType: function () {
            if (viewModel.model.LostItem.Type() === "")
                return { key: "type", message: "This field is required." }

            return { key: "ok", message: null }
        },
        isModelValid: function () {
            var valid = false;
            valid = viewModel.validateDateValue().message == null && viewModel.validateLocation().message == null;
            if (valid === true && viewModel.FeedbackType() === "incident")
                valid = viewModel.validateDestination().message == null;
            else if (valid === true && viewModel.FeedbackType() === "lostfound")
                valid = viewModel.validateCategory().message == null && viewModel.validateType().message == null;
            else if (valid === true && viewModel.FeedbackType() == "busshelter")
                valid = viewModel.validateLocation().message == null;

            return valid;
        },
        Routes: ko.observable('Maximum 4 numbers or letters'),
        dsResponseVia: new DevExpress.data.DataSource(AcTransitMobi.db.dsResponseVia),
        dsFeedBack: new DevExpress.data.DataSource(AcTransitMobi.db.dsFeedBack),
        FeedbackType: ko.observable(null),
        ActionType: function () {
            var ft = viewModel.FeedbackType();
            var a = viewModel.dsFeedBack.items().filter(function (item) {
                return item.action == ft;
            });
            return (a) && a.length == 1 ? a[0] : null;
        },
        FeedbackTypeClick: function (e) {
            viewModel.HideFeedbackDetails();
            $(".feedback-" + viewModel.FeedbackType()).show();
            $("#FeedbackComment").text(viewModel.CommentLabel());
            $("#feedback-submit").dxButton({ disabled: false });
            viewModel.resetDateTime();
            viewModel.toggleModelRequirements();
        },
        HideFeedbackDetails: function() {
            $(".feedback-field-option").hide();
            $(".feedback-incident").hide();
            $(".feedback-lostfound").hide();
            $(".feedback-busshelter").hide();
        },
        busTimeApi: new BusTimeApi(busTimeBase),

        directionsFromApi: ko.observable([]),
        selectedDirection: ko.observable(null),
        directionDisplay: function (item) {
            if (item != null)
                return item.name;
            return '';
        },
        DirectionUpdated: function (item) {
            this.model.Incident.Destination(item.name);
        },

        routesFromApi: ko.observable([]),
        selectedRoute: ko.observable(null),
        routeDisplay: function (item) {
            if (item != null)
                return item.rt + ' - ' + item.rtnm;
            return '';
        },
        RouteUpdated: function (item) {
            this.model.Incident.Line(item.rt);
            this.busTimeApi.getDirections(item.rt).then(function (result) {
                viewModel.directionsFromApi(result.response);
            });
        },

        SetupApi: function (e) {
            viewModel.busTimeApi.getRoutes().then(function (result) {
                viewModel.routesFromApi(result.response);
            });
        },
        FindOnMap: ko.observable('Find On Map'),
        ShowMap: ko.observable(false),
        FeedbackMapDone: function () {
            viewModel.model.Incident.Location(viewModel.model.Incident.Location().replace(/\s+<button.+button>/, ''));
            viewModel.ShowMap(false);
            viewModel.ShowMapClick();
            viewModel.FindOnMap('Search Again');
        },
        FindOnMapClick: function() {
            viewModel.ShowMap(true);
            viewModel.ShowMapClick();
        },
        ShowMapClick: function () {
            var options = {
                canvas: ".google-map",
                address: viewModel.model.Incident.Location,
                scrolling: viewModel.Scrolling,
                nearbyStops: viewModel.GetNearbyStops,
                routes: viewModel.Routes
            }
            viewModel.Scrolling(!viewModel.ShowMap());
            if (viewModel.ShowMap()) {
                AcTransitMobi.FindMe.start(options);
                $('#feedback-map-container').dxPopup("instance").show();
            } else {
                AcTransitMobi.FindMe.abort(options);
                $('#feedback-map-container').dxPopup("instance").hide();
                $('#FeedbackScrollView').dxScrollView("instance").scrollTo(0);
            }
        },
        HideMap: function () {
            viewModel.ShowMap(false);
            viewModel.ShowMapClick();
        },
        GetNearbyStops: function (lat, long) {
            var url = 'schedule/nearby-stops?latitude=' + lat + '&longitude=' + long + '&count=10';
            $.ajax({
                url: service + url,
                contentType: "text/plain; charset=utf-8"
            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log("error: " + errorThrown);
            }).done(function (data) {
                if (data.length == 0) return;
                AcTransitMobi.FindMe.showNearbyStops(data);
            });
        },
        Scrolling: function (enabled) {
            if (enabled != viewModel.view().option().disabled) return;
            console.log("Scrolling: " + enabled);
            viewModel.view().option({ disabled: !enabled });
        },
        RequestResponseClick: function () {
            var contactContainer = $("#feedback-contact-container");
            if (viewModel.model.RequestResponse()) {
                contactContainer.show();
            } else {
                viewModel.model.ResponseCriteria.Via(0);
                contactContainer.hide();
            }
        },
        ResponseViaClick: function() {
            viewModel.model.RequestResponse(viewModel.model.ResponseCriteria.Via() > 0);
        },
        ShowFile: ko.observable(false),
        ShowFileClick: function() {
            var fileContainer = $("#feedback-file-container");
            if (viewModel.ShowFile()) {
                fileContainer.show();
                $("#feedback-upload-input").on("change", function () {
                        if (event.target.files.length == 1 && event.target.files[0].type.indexOf("image/") == 0) {
                            var file = event.target.files[0];
                            viewModel.model.File = {
                                Filename: file.name,
                                Filesize: file.size,
                                ContentType: file.type,
                                AddDateTime: file.lastModifiedDate.toISOString(),
                                UpdDateTime: file.lastModifiedDate.toISOString()
                            };
                            $("#feedback-captured-image").show();
                            $("#feedback-captured-image").attr("src", URL.createObjectURL(event.target.files[0]));
                            var reader = new FileReader();
                            reader.onload = function (event) {
                                viewModel.model.File.Base64Data = event.target.result;
                            };
                            reader.readAsDataURL(event.target.files[0]);
                        }
                    });
                if (!("url" in window) && ("webkitURL" in window)) {
                    window.URL = window.webkitURL;
                }
            } else {
                viewModel.model.File = resetFile();
                $("#feedback-upload-input").val('');
                $("#feedback-captured-image").hide();
                fileContainer.hide();
            }
        },
        LoadStorage: function() {
            if (typeof (Storage) !== "undefined") {
                if (window.localStorage.ContactFirstName !== undefined)
                    viewModel.model.Contact.FirstName(window.localStorage.ContactFirstName);
                if (window.localStorage.ContactLastName !== undefined)
                    viewModel.model.Contact.LastName(window.localStorage.ContactLastName);
                if (window.localStorage.ContactMobilePhone !== undefined)
                    viewModel.model.Contact.MobilePhone(window.localStorage.ContactMobilePhone);
                if (window.localStorage.ContactEmailAddress !== undefined)
                    viewModel.model.Contact.EmailAddress(window.localStorage.ContactEmailAddress);
                if (window.localStorage.RequestResponse !== undefined)
                    viewModel.model.RequestResponse(window.localStorage.RequestResponse);
                setTimeout(function() {
                    viewModel.RequestResponseClick();
                    if (window.localStorage.ResponseCriteriaVia !== undefined)
                        viewModel.model.ResponseCriteria.Via(window.localStorage.ResponseCriteriaVia);
                }, 1);
            };
        },
        SaveStorage: function () {
            if (typeof (Storage) !== "undefined") {
                if (viewModel.model.Contact.FirstName().length > 0)
                    window.localStorage.setItem("ContactFirstName", viewModel.model.Contact.FirstName());
                if (viewModel.model.Contact.LastName().length > 0)
                    window.localStorage.setItem("ContactLastName", viewModel.model.Contact.LastName());
                if (viewModel.model.Contact.MobilePhone().length > 0)
                    window.localStorage.setItem("ContactMobilePhone", viewModel.model.Contact.MobilePhone());
                if (viewModel.model.Contact.EmailAddress().length > 0)
                    window.localStorage.setItem("ContactEmailAddress", viewModel.model.Contact.EmailAddress());
                window.localStorage.setItem("RequestResponse", viewModel.model.RequestResponse());
                window.localStorage.setItem("ResponseCriteriaVia", viewModel.model.ResponseCriteria.Via());
            };
        },
        LostItemCategories: ko.observableArray(Object.keys(AcTransitMobi.LostItems())),
        ErrorMessageText: ko.observable(''),
        IncidentDateTimeErrorMessageText: ko.observable(''),
        IncidentTimeErrorMessageText: ko.observable(''),
        LocationErrorMessageText: ko.observable(''),
        CategoryErrorMessageText:ko.observable(''),
        TypeErrorMessageText: ko.observable(''),
        DestinationErrorMessageText: ko.observable(''),
        ClearErrors: function() {
            viewModel.ErrorMessageText('');
            viewModel.IncidentDateTimeErrorMessageText('');
            viewModel.IncidentTimeErrorMessageText('');
            viewModel.LocationErrorMessageText('');
            viewModel.DestinationErrorMessageText('');
            viewModel.CategoryErrorMessageText('');
            viewModel.TypeErrorMessageText('');
        },
        IncidentDateUpdated: function (e) {
            viewModel.IncidentDateTimeErrorMessageText('');
            viewModel.IncidentTimeErrorMessageText('');
            var dateValueMsg = viewModel.validateDateValue().message;
            if (dateValueMsg != null) {
                if (viewModel.validateDateValue().key === "time")
                    viewModel.IncidentTimeErrorMessageText(dateValueMsg);
                else
                    viewModel.IncidentDateTimeErrorMessageText(dateValueMsg);
            }
        },
        IncidentTimeUpdated: function (e) {
            var time = viewModel.timeValue();
            if ((typeof time !== "undefined") && (time !== "") && (viewModel.isValidDateTime(viewModel.dateValue)))
                viewModel.dateValue().setHours(time.getHours(), time.getMinutes(), time.getSeconds());
            viewModel.IncidentDateUpdated();
        },
        DestinationUpdated: function() {
            viewModel.DestinationErrorMessageText('');
            var destinationMsg = viewModel.validateDestination().message;
            if (destinationMsg != null) {
                if (viewModel.validateDestination().key === "destination")
                    viewModel.DestinationErrorMessageText(destinationMsg);
            } else
                viewModel.DestinationErrorMessageText('');
        },
        CategoryUpdated: function () {
            viewModel.CategoryErrorMessageText('');
            var categoryMsg = viewModel.validateCategory().message;
            if (categoryMsg != null) {
                if (viewModel.validateCategory().key === "category")
                    viewModel.CategoryErrorMessageText(categoryMsg);
            } else
                viewModel.CategoryErrorMessageText('');
        },
        TypeUpdated: function () {
            viewModel.TypeErrorMessageText('');
            var typeMsg = viewModel.validateType().message;
            if (typeMsg != null) {
                if (viewModel.validateType().key === "type")
                    viewModel.TypeErrorMessageText(typeMsg);
            } else
                viewModel.TypeErrorMessageText('');
        },
        feedbackTemplate: ko.observable(''),
        SubmitPromise: {},
        Submit: function () {
            viewModel.ClearErrors();
            $("#feedback-submit").dxButton({ disabled: true });
            viewModel.model.Incident.DateTime = viewModel.getDateValue();
            viewModel.model.ActionEnum = viewModel.ActionType().id - 1;
            ko.validation.configuration.insertMessages = false;
            var validationResult = ko.validation.group(viewModel.model, { deep: true });
            var validation = validationResult();
            var validationLength = validation.length;
            var validModel = viewModel.isModelValid();
            var valid = validationLength == 0 && validModel;
            if (valid) {
                $("#Submitting").show();
                $("#ThankYou").hide();
                var url = service + 'feedback/' + viewModel.FeedbackType();
                var data = ko.toJSON(viewModel.model);
                setTimeout(function() {
                    $.submitPromise = $.ajax({
                        type: "POST",
                        url: url,
                        data: data,
                        contentType: "application/json; charset=utf-8",
                        crossDomain: true,
                        dataType: "json"
                    }).done(function(result) {
                        viewModel.SaveStorage();
                        $("#Submitting").hide();
                        $("#ThankYou").show();
                        $(".FeedbackFormSection").hide();
                        $('#FeedbackScrollView').dxScrollView("instance").scrollTo(0);
                    }).fail(function (e) {
                        var errors = (typeof e.responseText !== "undefined" ? ': ' + JSON.parse(e.responseText).Errors.join(", ") : "");
                        viewModel.ErrorMessageText("Error: " + e.statusText + errors);
                        $("#feedback-submit").dxButton({ disabled: false });
                    });

                 },100);
            } else {
                $("#feedback-submit").dxButton({ disabled: false });
                viewModel.IncidentDateUpdated();
                viewModel.LocationUpdated();
                if (viewModel.FeedbackType() === "incident")
                    viewModel.DestinationUpdated();
                else if (viewModel.FeedbackType() === "lostfound") {
                    viewModel.CategoryUpdated();
                    viewModel.TypeUpdated();
                }
                validationResult.showAllMessages();
            }
        },
        SetCSS: function() {
            var device = DevExpress.devices.current();
            switch (device.platform) {
                case 'android':
                    $(".dx-switch-off").css("background", "white");
                    $(".dx-theme-android .dx-switch .dx-switch-on, .dx-theme-android .dx-switch .dx-switch-off").css("color", "#000").css("font-weight", "bold");
                    break;
                case 'generic':
                    $(".dx-field").css("border-bottom", "0");
                    break;
                case 'ios':
                    $(".dx-theme-ios.dx-version-major-7 .dx-field").css("border-bottom", "0");
                    break;
            }
        },
        ClearCSS: function() {
            var device = DevExpress.devices.current();
            switch (device.platform) {
                case 'android':
                    $(".dx-theme-android .dx-switch .dx-switch-on, .dx-theme-android .dx-switch .dx-switch-off").css("color", "#ccc").css("font-weight", "");
                    break;
                case 'generic':
                    $(".dx-field").css("border-bottom", "0");
                    break;
                case 'ios':
                    $(".dx-theme-ios.dx-version-major-7 .dx-field").css("border-bottom", "1px solid #c8c8c8;");
                    break;
            }
        },
        viewShown: function (args) {
            viewModel.SetCSS();
            viewModel.HideFeedbackDetails();
            viewModel.SetupApi();
            window.utils.triggerGA('/index.html#Feedback', '#Feedback');
        },
        viewHidden: function(args) {
            ko.validation.configuration.insertMessages = true;
            viewModel.ClearCSS();
        }
    };
    viewModel.model.Contact.MobilePhone.extend({
        digit: true,
        minLength: { params: 10, message: "Must be 10 digits in length." },
        required: {
            onlyIf: function() {
                return viewModel.model.ResponseCriteria.Via() == 2;
            }
        }
    });
    viewModel.phoneRequired = ko.computed(function() {
        return viewModel.model.ResponseCriteria.Via() == 2 ? "*" : "";
    });

    viewModel.Location = ko.computed(function () {
        var index = viewModel.model.Incident.Location().indexOf('<button');
        return index > 0 ? viewModel.model.Incident.Location().substring(0, index) : viewModel.model.Incident.Location();
    });

    viewModel.errors = ko.validation.group(viewModel);
    viewModel.LostItemTypes = ko.computed(function() {
        if (!viewModel.model.LostItem.Category()) {
            return new Array();
        }
        return AcTransitMobi.LostItems()[viewModel.model.LostItem.Category()];
    });
    viewModel.CommentLabel = ko.computed(function() {
        switch (viewModel.FeedbackType()) {
            case "incident": return "Please describe the incident";
            case "ask": return "Ask your question";
            case "suggestion": return "Please state your suggestion";
            case "lostfound": return "Describe your lost item (brand, color, size, unique markings, etc)";
            case "busshelter": return "Please describe in detail the location and condition of the shelter.";
        }
    });

    function resetFile() {
        return {
            Filename: '',
            Filesize: 0,
            ContentType: '',
            Base64Data: ''
        };
    };

    viewModel.LoadStorage();
    return viewModel;
};

// FaresAndClipper -->

AcTransitMobi.FaresAndClipper = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        TopSectionContent: ko.observable(null),
        AllFaresValue: ko.observableArray(),
        AllClipperValue: ko.observableArray(),

        url: service + 'fare/info',

        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.TopSectionContent(utils.fixContent(data.TopSectionContent));
                self.AllFaresValue.removeAll();
                self.AllClipperValue.removeAll();
                for (var key = 0; key < data.FareLinks.length; key++) {
                    self.AllFaresValue.push({ name: data.FareLinks[key].Key, links: data.FareLinks[key].Value });
                }
                for (var key = 0; key < data.ClipperCardLinks.length; key++) {
                    self.AllClipperValue.push({ name: data.ClipperCardLinks[key].Key, links: data.ClipperCardLinks[key].Value });
                }
                $("#FaresAndClipperTopContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchor(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#FaresAndClipperTopContent", scrollTopElement: "#FaresAndClipperTopContent", initialUrl: self.url, isPanelVisible: self.loadPanelVisible, onLoaded: self.clickCallback });
                self.loadPanelVisible(false);
                self.scrollToTop();
            });
        },

        scrollToAnchor: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#FaresAndClipperScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTop: function () {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#FaresAndClipperScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        clickCallback: function () {
            var self = this;
            $("#FaresAndClipperTopContent").show();
            $("#FaresAndClipperMenu").hide();
            AcTransitMobi.FaresAndClipper().scrollToTop();
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html#FaresAndClipper', '#FaresAndClipper');
            viewModel.load();
        },

        processItemClick: function (da) {
            var Url = da.itemData.links;
            var urlSubString = Url.substr(1).split(".");
            var length = urlSubString.length;
            var findLastIndexValue = urlSubString[length - 1];
            if (findLastIndexValue == 'pdf') {
                window.open(Url, '_blank');
            }
            else {
                AcTransitMobi.app.navigate({ view: "FaresAndEligibility", id: Url });
            }
        }

    };

    return viewModel;
};


// FaresAndEligibility -->

AcTransitMobi.FaresAndEligibility = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        Content: ko.observable(null),
        //errorMessageText: ko.observable(null),
        //headline: ko.observable(null),

        url: params.id,

        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.Content(utils.fixContent(data.Content));
                $("#FaresAndEligibilityContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorFaresAndEligibility(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#FaresAndEligibilityContent", scrollTopElement: "#FaresAndEligibilityScrollView", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopFaresAndEligibility();
            });
        },
        scrollToAnchorFaresAndEligibility: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#FaresAndEligibilityScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopFaresAndEligibility: function scrollToTopFaresAndEligibility() {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#FaresAndEligibilityScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html#FaresAndEligibility', '#FaresAndEligibility');
            viewModel.load();
        }
    };

    return viewModel;
};

// MobileApps -->

AcTransitMobi.MobileApps = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        Content: ko.observable(null),

        url: service + 'article/mobile-apps',

        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.Content(utils.fixContent(data.Content));
                $("#MobileAppsContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorMobileApps(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#MobileAppsContent", scrollTopElement: "#MobileAppsScrollView", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopMobileApps();
            });
        },
        scrollToAnchorMobileApps: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#MobileAppsScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopMobileApps: function scrollToTopMobileApps() {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#MobileAppsScrollView').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html#MobileApps', '#MobileApps');
            viewModel.load();
        }
    };

    return viewModel;
};

// News -->

AcTransitMobi.News = function (params) {
    var viewModel = {
        errorMessageText: ko.observable(null),
        newsDataSource: new DevExpress.data.DataSource({
            load: function (loadOptions) {
                if (loadOptions.pageIndex > 0) return null;
                window.utils.triggerGA('/index.html#News', '#News');
                return $.getJSON(service + 'articles/latest'); //'news/articles');
            },
            map: function (item) {
                if (item != null) {
                    $("#errorMessageDiv").hide();
                    $("#ServicesDiv").show();
                    return {
                        id: item.Key,
                        title: item.Title,
                        PostDateTime: new Date(item.PostDateTime).toDateString()
                    };
                }
                else {
                    viewModel.errorMessageText = errorMessage;
                    $("#ServicesDiv").hide();
                    $("#errorMessageDiv").show();
                }
            }
        }),
        viewShown: function (args) {
        }

    };

    return viewModel;
};

// NewsDetails -->

AcTransitMobi.NewsDetails = function (params) {

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        id: ko.observable(null),
        Content: ko.observable(null),
        date: ko.observable(null),
        title: ko.observable("News"),
        headline: ko.observable(null),

        url: service + 'article/' + encodeURIComponent(params.id),

        load: function () {
            var self = this;
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                self.id(data.Key);
                self.date(new Date(data.PostDateTime).toDateString());
                self.headline(data.Title);

                self.Content(utils.fixContent(data.Content));

                $("#NewsDetailsContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorNewsDetails(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#NewsDetailsContent", scrollTopElement: "#NewsNoticesDetailsScrollDiv", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopNewsDetails();
            });
        },
        scrollToAnchorNewsDetails: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#NewsNoticesDetailsScrollDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopNewsDetails: function () {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#NewsNoticesDetailsScrollDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html#NewsDetails', '#NewsDetails');
            viewModel.load();
        }
    };

    return viewModel;
};

// Article -->

AcTransitMobi.Article = function (params) {

    var postName = "", newPostName = "";
    try { newPostName = new RegExp(".*/([^/]+)/{0,1}").exec(utils.getUrlParameterByName("RedirUri") || "//noarticle/")[1] || "noarticle"; }
    catch (e) { }
    if (newPostName == "noarticle" || newPostName == "") window.utils.GotoView("#home");
    else postName = newPostName;

    var viewModel = {
        loadPanelVisible: ko.observable(true),
        id: ko.observable(null),
        Content: ko.observable(null),
        date: ko.observable(null),
        title: ko.observable(null),
        headline: ko.observable(null),

        url: service + 'article/' + postName + '?RedirUri=' + utils.getUrlParameterByName("RedirUri"),

        load: function () {
            var self = this;
            var jqxhr = $.getJSON(self.url, function(data, textStatus, jqXHR) {
                self.id(data.Key);
                self.date(new Date(data.PostDateTime).toDateString());
                self.title(data.Title);
                self.Content(utils.fixContent(data.Content));
                $("#ArticleContent a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #                    
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorArticle(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#ArticleContent", scrollTopElement: "#ArticleScrollDiv", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopArticle();
            }).fail(function () {
                self.loadPanelVisible(false);
                self.title("Page not found.  Please try Full Site.");
                self.scrollToTopArticle();
            });
        },
        scrollToAnchorArticle: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#ArticleScrollDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopArticle: function () {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#ArticleScrollDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        viewShown: function () {
            window.utils.triggerGA('/index.html?RedirUri=' + utils.getUrlParameterByName("RedirUri") + '#Article', '#Article');
            viewModel.load();
        }
    };

    return viewModel;
};


// PickLineInfo -->

AcTransitMobi.PickLineInfo = function (params) {

    var viewModel = {
        id: ko.observable(params.id || "All Lines"),
        name: ko.observable(params.name || ""),
        title: ko.observable("Line " + params.name),
        viewed: ko.observable(false),
        viewShown: function () {
            var self = this;
            if (self.viewed())
                self.back();
            var dev = DevExpress.devices.current();
            $(".dx-button-content").css("width", dev.ios ? "100%" : "250px");
            if (dev.ios) return;
            utils.verticalCenterDivs("#pickLineInfoOuter", "#pickLineInfoMiddle", "#pickLineInfoInner", true, "250px");
        },
        displaySchedules: function () {
            var self = viewModel;
            self.viewed(true);
            if (self.name() != null)
                AcTransitMobi.app.navigate({ view: "LineSchedules", id: self.id(), name: self.name() });
        },
        displayMap: function () {
            var self = viewModel;
            self.viewed(true);
            if (self.name() != null)
                AcTransitMobi.app.navigate({ view: "LineMap", id: self.id(), name: self.name() });
        },
        displayServiceNotices: function () {
            var self = viewModel;
            self.viewed(true);
            if (self.name() != null)
                AcTransitMobi.app.navigate({ view: "LineServiceNotices", id: self.id(), name: self.name() });
        },
        back: function() {
            try {
                (new DevExpress.framework.dxCommand({ action: "#_back" })).execute();
            } catch (e) { }
        }
    };

    return viewModel;
};

// Schedules -->

AcTransitMobi.Schedules = function (params) {
   "use strict";

    var viewModel = {
        id: ko.observable(null),
        nameValue: ko.observable(null),
        displayExprValue: ko.observable(null),
        currentValue: ko.observable(null),
        jData: ko.observable(null), //is not getting used.
        allLines: [],
        filteredLines: ko.observableArray([]),
        selectedLineValue:ko.observable(''),
        localdata: ko.observableArray([]),
        categoryName: ko.observable(null),
        buttonsVisible: ko.observable(false),
        scheduleActionSheetData: ko.observable([]),
        scheduleActionSheetVisible: ko.observable(false),
        Notice: ko.observable(""),
        currentLine: null,
        inputText: null,
        initial: function () {
            $.getJSON(service + 'lines/category/All Lines', function (data) {
                for (var i in data) {
                    if (data[i].KeyValue.Value == 'All Lines') {
                        viewModel.displayExprValue(i);
                        break;
                    }
                }
                viewModel.setLines(data, 0, viewModel.allLines);
                viewModel.filteredLines(viewModel.allLines);
                if (viewModel.filteredLines().length > 0) {
                    $("#divLoading").hide();
                    $(".dx-fieldset").css("margin", "0");
                    var newHeight = "-=" + ($(".dx-fieldset").height() + 15);
                    $(".dx-list").height(newHeight);
                }
                else {
                    $("#divLoading").text('No data to display');
                }
            });
        },
        setLines: function (data,keyIndex,destArray) {
            if (keyIndex != null && keyIndex >= 0) {
                var indexVal = keyIndex;
                viewModel.categoryName = data[indexVal].KeyValue.Value;

                var length = data[indexVal].Lines.length;
                for (var index = 0; index < length; index++) {
                    var lines = data[indexVal].Lines[index];
                    var value = lines.KeyValue.Value;
                    destArray.push({ name: value });
                }
            }
        },
        displayAll: function (lineId) {
            var self = this.viewModel;
            self.lineValue = self.currentLine;
            if (self.lineValue != null) {
                self.id = self.categoryName;
                self.nameValue = self.lineValue;
                self.navigate("SchedulesMapServiceNoticesDetails", self.id, self.nameValue);
            }
        },
        displaySchedules: function (lineId) {
            var self = this.viewModel;
            self.lineValue = self.currentLine;
            if (self.lineValue != null) {
                self.id = self.categoryName;
                self.nameValue = self.lineValue;
                self.navigate("LineSchedules", self.id, self.nameValue);
            }
        },
        displayMap: function (lineId) {
            var self = this.viewModel;
            self.lineValue = self.currentLine;
            if (self.lineValue != null) {
                self.id = self.categoryName;
                self.nameValue = self.lineValue;
                self.navigate("LineMap", self.id, self.nameValue);
            }
        },
        displayServiceNotices: function (lineId) {
            var self = this.viewModel;
            self.lineValue = self.currentLine;
            if (self.lineValue != null) {
                self.id = self.categoryName;
                self.nameValue = self.lineValue;
                self.navigate("LineServiceNotices", self.id, self.nameValue);
            }
        },
        categoryChanged: function (lineId) {
            var self = this;
            self.currentValue(null);
            return self.checkButtons(lineId);
        },
        lineChanged: function (lineId) {
            var me = this;
            var value=me.currentValue();
            if ( value!=null & value!='') {
                me.selectedLineValue('');
                me.inputText.blur(); //take the focus away.
            }
        },
        checkButtons: function (lineId) {
            var self = this;
            var isEnabled = self.displayExprValue() != 0;// && self.currentValue() != null;
            self.buttonsVisible(isEnabled);
            self.scheduleActionSheetVisible(isEnabled);
            $(".dx-action-sheet-container div div").attr("style", "width: 100% !important");
            return isEnabled;
        },
        viewShown: function () {
            var self = this;
            if (Zoom.isEnabled())
                Zoom.disable();
            //self.currentValue(null);
            if (new Date() >= new Date("06/18/2017") && new Date() <= new Date("08/19/2017")){
                self.Notice('Lines 600-699 are suspended for the summer.<br/>Information on back-to-school bus lines will be available on July 17.');
            }
            else
            {
                //self.Notice('<a href="#NewsDetails/major-service-changes-march-26">See info on service changes March 26, 2017</a>');
            }
            
            self.scheduleActionSheetData(
            [
                { text: "View Schedules", clickAction: self.displaySchedules, viewModel: self, width: "100%" },
                { text: "View Map", clickAction: self.displayMap, viewModel: self, width: "100%" },
                { text: "View Service Notices", clickAction: self.displayServiceNotices, viewModel: self, width: "100%" }
            ]);
            self.inputText = $('#textboxdiv input[type="text"]');
            self.inputText.unbind('keyup');
            self.inputText.keyup(function (event) {
                var me = this;
                viewModel.filteredLines(jQuery.grep(viewModel.allLines, function (n, i) {
                    return n.name.toLowerCase().indexOf($(me).val().toLowerCase()) > -1;
                }));

                var value = self.selectedLineValue();
                if (value!=null && value!='') self.currentValue(null);
                if (event.keyCode == 13) {
                    $(this).blur();
                    viewModel.goClicked();
                }
            });

            //self.checkButtons();
        },
        navigate: function(view,id,name){
            setTimeout(function () {
                AcTransitMobi.app.navigate({ view: view, id: id, name:name});
            }, 500);
        },
        lineClicked: function(e){
            var me = viewModel;
            //me.currentValue(e.name);
            me.currentLine =me.correctLineIfValid(e.name);
            me.go();
        },
        goClicked: function(){
            var me = viewModel;
            var line = '';
            line = me.correctLineIfValid(me.selectedLineValue());
            if (line == null || line == '')
                me.currentLine =null;
            else
                me.currentLine = line;
            me.go();
        },
        go: function () {
            var me = viewModel;
            if (me.currentLine == null || me.currentLine == '')
            {
                DevExpress.ui.notify('Line does not exist', 'Error', 3000);
            }
            else {
                me.displayExprValue(1);// make sure it is not null.
                me.checkButtons(me.currentLine);
            }
        },
        //getSelectedLine: function () {
        //    var me = viewModel;
        //    var line = me.selectedLineValue();
        //    if (line == null || line == '')
        //        line = me.currentValue();
        //    return line;
        //},
        correctLineIfValid: function (line) {
            var me = viewModel;
            var exist = false;
            var selectedLineLowerCase = line.toLowerCase();
            var selectedLine = -1;
            for (var i in me.allLines) {
                if (me.allLines[i].name.toLowerCase() == selectedLineLowerCase) {
                    selectedLine = i;
                    line = me.allLines[selectedLine].name;
                    exist = true;
                    break;
                }
            }
            if (exist)
                return line;
            return null;
        }

    };
    viewModel.initial();
    return viewModel;
};

// LineSchedules -->

AcTransitMobi.LineSchedules = function (params) {

    var viewModel = {
        title: ko.observable(params.id.replace(/([a-z](?=[A-Z]))/g, '$1 ') + ' Line ' + params.name),
        errorMessageText: ko.observable(null),
        loadPanelVisible: ko.observable(false),
        Routes: ko.observableArray([]),
        id: ko.observable(params.id),
        lineId: ko.observable(params.name),
        isLoaded: false,
        loadContent: function () {
            var self = this;
            if (self.isLoaded)
                return;
            try {
                $.getJSON(service + 'lines/line/' + params.name)
                    .done(function (result) {
                        $("#RoutesDiv").show();
                        self.Routes.removeAll();
                        if (result.length < 1 || result.Routes[0] == null)
                            return;
                        for (var index = 0; index < result.Routes.length; index++) {
                            self.Routes.push({
                                id: self.lineId(),
                                name: result.Routes[index].DirectionCode.Description + ', ' + result.Routes[index].DayCode.KeyValue.Value + ' Schedule'
                            });
                        }
                        self.loadPanelVisible(false);
                    }).fail(function (result) {
                        $("#errorMessageDivService").show();
                        self.loadPanelVisible(false);
                    });

            } catch (e) {
            }

        },
        viewShown: function () {
            var self = this;
            $("#errorMessageDivService").hide();
            if (typeof (self.lineId) === "undefined")
                window.utils.triggerGA('/index.html#SchedulesList', '#SchedulesList');
            self.isLoaded = typeof self.Routes !== "undefined" && self.Routes().length > 0;
            if (!self.isLoaded) {
                self.loadPanelVisible(true);
                $("#RoutesDiv").hide();
                self.loadContent();
            }
        }
    };

    return viewModel;
};

// LineMap -->

AcTransitMobi.LineMap = function (params) {

    var viewModel = {
        title: ko.observable(params.id + ' Line ' + params.name),
        loadPanelVisible: ko.observable(false),
        errorMessageText: ko.observable(null),
        id: ko.observable(params.id),
        lineId: ko.observable(params.name),
        imagePath: ko.observable(),
        mapDivStyle: ko.observable(),
        imageStyle: ko.observable(),
        alternateText: ko.observable('The map will become available soon.'),
        alternateTextVisible: ko.observable(false),
        viewMap: function (data) {
            viewModel.imagePath("maps/images/Lines/version_" + mapsVersion + "/" + params.name + ".svg");
        },
        viewShown: function () {
            var self = this;
            //self.loadPanelVisible(true);
            var win8 = DevExpress.devices.current().isWin8Device;
            var containerDiv = $("#mapDiv");
            var img = $("#mapDiv>div>img");
            self.mapDivStyle("overflow:auto;position:relative;");
            containerDiv.width("100%");
            containerDiv.height("100%");
            img.load(function () {
                self.imageStyle(win8 ? "width:auto;height:auto;" : "width:100%");
                self.loadPanelVisible(false);
            });
            img.error(function (e) {
                viewModel.imagePath('');
                self.imageStyle("display:none");
                self.loadPanelVisible(false);
                self.alternateTextVisible(true);
            });
            Zoom.enable();
            if (typeof (self.lineId) === "undefined")
                window.utils.triggerGA('/index.html#LineMap', '#LineMap');
            if (params.name != 'null')
                viewModel.imagePath("maps/images/Lines/version_" + mapsVersion + "/" + params.name + ".svg");
        }
    };
    viewModel.onImagePathChanged = ko.computed(function () {
        var self = viewModel;
        var imgpath = self.imagePath();
        if (imgpath && imgpath!='') {
            self.alternateTextVisible(false);
            self.loadPanelVisible(true);
        }
    });
    return viewModel;
};

// LineServiceNotices -->

AcTransitMobi.LineServiceNotices = function (params) {

    var viewModel = {
        title: ko.observable(params.id + ' Line ' + params.name),
        errorMessageText: ko.observable(null),
        loadPanelVisible: ko.observable(false),
        ServiceNotices: ko.observableArray([]),
        id: ko.observable(params.id),
        lineId: ko.observable(params.name),
        loadContent: function () {
            var self = this;
            
            try {
                var busTime = new BusTimeApi(busTimeBase);
                busTime.getServiceBulletinsAsDeferred().always(function() {
                    $("#ScheduleServiceDiv").show();
                    self.ServiceNotices.removeAll();

                }).done(function (data) {
                    var notices = [];
                    data = data || [];
                    for (var i = 0; i < data.length; i++) {
                        var b = data[i];
                        b.srvc = b.srvc || [];
                        for (var j = 0; j < b.srvc.length; j++) {
                            var srv = b.srvc[j];
                            
                            if (srv.rt == params.name) {
                                notices.push({
                                    id: b.nm,
                                    name: b.sbj,
                                    lineId: params.name
                                });
                            }

                        }
                    }

                    
                    if (notices.length < 1 || notices[0] == null)
                        return;                   
                    self.ServiceNotices(notices);
                    self.loadPanelVisible(false);


                }) .fail(function (result) {
                            $("#errorMessageDivService").show();
                            self.loadPanelVisible(false);
                });
                //$.getJSON(service + 'servicenotices/line/' + params.name)
                //    .done(function (result) {
                //        $("#ScheduleServiceDiv").show();
                //        self.ServiceNotices.removeAll();
                //        var notices = [];
                //        if (result.length < 1 || result[0] == null)
                //            return;
                //        for (var index = 0; index < result.length; index++) {
                //            notices.push({
                //                id: result[index].Key,
                //                name: result[index].Title,
                //                lineId: self.lineId()
                //            });
                //        };
                //        self.ServiceNotices(notices);
                //        self.loadPanelVisible(false);
                //    })
                //    .fail(function (result) {
                //        $("#errorMessageDivService").show();
                //        self.loadPanelVisible(false);
                //    });

            } catch (e) {
                console.log(e);
            }
        },
        viewShown: function () {
            var self = this;
            self.loadPanelVisible(true);
            $("#ScheduleServiceDiv").hide();
            $("#errorMessageDivService").hide();
            if (typeof (self.lineId) === "undefined")
                window.utils.triggerGA('/index.html#ServiceNoticesDetails2', '#ServiceNoticesDetails2');
            self.loadContent();
        }
    };

    return viewModel;
};

// SchedulesTable -->

AcTransitMobi.SchedulesTable = function (params) {

    var viewModel = {
        errorMessageText: ko.observable(null),
        title: ko.observable(),
        loadPanelVisible: ko.observable(false),
        columns: ko.observableArray(),
        rows: ko.observableArray([]),
        showScrollViewSchedulesTable: ko.observable(false),
        fixScrollbars: function () {
            window.setTimeout(function () {
                //$("#SchedulesTableContent").parent().height("93%"); //.css("overflow", "scroll");
            }, 10);
        },
        fixTableColors: function () {
            $("#ScheduleTable thead tr th div").attr("style", "text-shadow: none !important; color: white !important");
        },
        loadContent: function () {
            var self = this;
            try {
                $.getJSON(service + 'lines/line/' + params.id + '/schedules')
                    .done(function (result) {
                        var lineValue = {};
                        var data = {};
                        for (var idx = 0; idx < result.Routes.length; idx++) {
                            lineValue = result.Routes[idx].DirectionCode.Description + ', ' + result.Routes[idx].DayCode.KeyValue.Value + ' Schedule';
                            data = result.Routes[idx].Schedule;
                            if (lineValue == params.name)
                                break;
                        }

                        self.title("Line " + params.id + " " + lineValue);

                        self.columns([]);
                        for (var key in data[0]) {
                            self.columns.push(key);
                        }

                        var newRows = [];
                        for (var idx in data) {
                            var newRow = [];
                            for (var key in data[idx]) {
                                var cell = ko.observable(data[idx][key]);
                                newRow.push(cell);
                            }
                            newRows.push(newRow);
                        }
                        self.rows(newRows);

                        $("#ErrorMessageDiv").hide();
                        $("#SchedulesTableDiv").show();
                        self.showScrollViewSchedulesTable(true);
                        self.fixTableColors();
                        self.fixScrollbars();
                        self.loadPanelVisible(false);
                    }).fail(function (result) {
                        self.errorMessageText(errorMessage);
                        $("#SchedulesTableDiv").hide();
                        $("#ErrorMessageDiv").show();
                        self.showScrollViewSchedulesTable(false);
                        self.loadPanelVisible(false);
                    });

            } catch (e) {
            }
        },
        viewShown: function () {
            var self = this;
            self.loadPanelVisible(true);
            window.utils.triggerGA('/index.html#ServiceNoticesDetails', '#ServiceNoticesDetails');
            self.loadContent();
        }
    };

    return viewModel;

};

// ServiceNotices -->

AcTransitMobi.ServiceNotices = function (params) {
    //View Model...
    var viewModel = {
        errorMessageText: ko.observable(null),
        errorMessageText: errorMessage,
        //Bind Data in DataSource...
        serviceNoticesdataSource: new DevExpress.data.DataSource({
            load: function (loadOptions) {
                window.utils.triggerGA('/index.html#ServiceNotices', '#ServiceNotices');

                var busTime = viewModel.busTime;
                if (busTime == null) {
                    busTime = new BusTimeApi(busTimeBase);
                }
                var p=$.Deferred();
                return busTime.getServiceBulletinsAsDeferred(null,null,null);//.then(function(data){
                //     data=data||{};
                //     data.response=data.response|| [];
                //     p.resolve(data.response);
                // }).catch(function(){
                //     p.reject();
                // });
                //  return p;
                //var jData = $.getJSON(service + 'servicenotices/active');
                ////   $("#ServicesDiv").hide();
                //return jData;
            },
            map: function (item) {
                if (item != null) {
                    $("#errorMessageDiv").hide();
                    $("#ServicesDiv").show();
                    var d = item.mod.split(' ')[0];
                    var dateStr = d.substring(0, 4) + '/' + d.substring(4, 6) + '/' + d.substring(6, 8);

                    return {
                        id: item.nm,
                        title: item.sbj,
                        Content: item.dtl,
                        //PostDateTime: dateStr
                        PostDateTime: new Date(dateStr).toDateString()

                    };
                }
                else {
                    $("#ServicesDiv").hide();
                    $("#errorMessageDiv").show();
                }
            }
        }),
        viewShown: function (args) {
        }
    };

    return viewModel;
};

// ServiceNoticesDetails -->

AcTransitMobi.ServiceNoticesDetails = function (params) {

    var viewModel = {
        id: ko.observable(null),
        name: ko.observable(null),
        headline: ko.observable(null),
        description: ko.observable(null),
        date: ko.observable(null),
        appliesToLines: ko.observable(null),
        loadPanelVisible: ko.observable(true),
        lineId: ko.observable(params.lineId),
        title: ko.observable("Line " + params.lineId + " Service Notice"),

        //url: service + 'servicenotices/' + params.id,

        viewShown: function (args) {
            var self = this;
            window.utils.triggerGA('/index.html#LineServiceNotices', '#LineServiceNotices');
            $.getJSON(self.url, function (data, textStatus, jqXHR) {
                var param = args.viewInfo.model.params;
                var item = data;
                self.id(item.Title);
                self.headline(item.Title);
                self.date(new Date(item.PostDateTime).toDateString());
                self.description(utils.fixContent(item.Content));
                var atl = utils.GetAppliesToLine(item.Taxonomies[0].Terms);
                self.appliesToLines(atl === null ? '' : '<strong>Applies to Line(s):</b> ' + atl);
                $("#LineServicesNoticeDiv a[href^='#']").each(function (index, element) {
                    element.id = element.href.split('#')[1]; // element.id = anything after #
                }).bind("click", function (eventObject) {
                    self.scrollToAnchorLineServiceNotices(eventObject.currentTarget.id); // element.id
                }).removeAttr("href");
                DeepLink.getInstance().CatchClick({ contentElement: "#LineServicesNoticeDiv", scrollTopElement: "#LineServicesNoticeDiv", initialUrl: self.url, isPanelVisible: self.loadPanelVisible });
                self.loadPanelVisible(false);
                self.scrollToTopLineServiceNotices();
            });
        },

        scrollToAnchorLineServiceNotices: function (lineId) {
            var aTag = $("a[name='" + lineId + "']");
            if (aTag.length != 1) return;
            $('#LineServicesNoticeDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        },

        scrollToTopLineServiceNotices: function () {
            var aTag = $("#Top");
            if (aTag.length != 1) return;
            $('#LineServicesNoticeDiv').dxScrollView("instance").scrollTo(aTag.position().top);
        }
    };

    return viewModel;
};

// ServiceNoticesDetails2 -->

AcTransitMobi.ServiceNoticesDetails2 = function (params) {
   
    function scrollToAnchorServiceNoticesDetails(lineId) {
        var aTag = $("a[name='" + lineId + "']");
        if (aTag.length != 1) return;
        $('#ServiceNoticesDetailsDiv').dxScrollView("instance").scrollTo(aTag.position().top);
    };
    function scrollToTopServiceNoticesDetails() {
        var aTag = $("#Top");
        if (aTag.length != 1) return;
        $('#ServiceNoticesDetailsDiv').dxScrollView("instance").scrollTo(aTag.position().top);
    }
    //var url = service + 'servicenotices/' + params.id;
  var viewModel = function () {
        var me = this;
        this.id = ko.observable(null);
        this.headline = ko.observable(null);
        this.description = ko.observable(null);
        this.date = ko.observable(null);
        this.appliesToLines = ko.observable(null);
        this.loadPanelVisible = ko.observable(true);
        this.viewShown = function (args) {                        
            window.utils.triggerGA('/index.html#ServiceNoticesDetails2', '#ServiceNoticesDetails2');
            var busTime = viewModel.busTime;
            if (busTime == null) {
                busTime = new BusTimeApi(busTimeBase);
            }
            busTime.getServiceBulletinsAsDeferred(null, null, null).done(function (data) {                
                for (var i = 0; i < data.length; i++) {
                    if (data[i].nm == params.id) {
                        var item = data[i];
                        var lines = "";

                        var linesDistinct = item.srvc.distinctBy(["rt"]);
                        //console.log(linesDistinct);
                        for (var j = 0; j < linesDistinct.length; j++) {
                            if (lines != '') lines += ", ";
                            lines += linesDistinct[j].rt;
                        }
                        if (lines == '') lines = "All";

                        //console.log(item);
                        //me.id(item.nm);
                        me.id(item.sbj);
                        me.headline(item.sbj);
                        var newText = window.utils.wrapURLs(item.dtl.toString(), false);

                        //newText = newText.replace(regexPattern,
                        //    '<a href="$0">($0)</a>');
                        //var regex = new RegExp(/\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[A-Z0-9+&@#\/%=~_|]/im);
                        
                        
                        //me.date(new Date(item.PostDateTime).toDateString());
                        //me.description(utils.fixContent(item.dtl.toString()));
                        me.description(utils.fixContent(newText));
                        //var atl = utils.GetAppliesToLine(item.Taxonomies[0].Terms);
                        //appliesToLines(atl === null ? '' : '<strong>Applies to Line(s):</b> ' + atl);
                        me.appliesToLines(lines === null ? '' : '<strong>Applies to Line(s):</b> ' + lines);
                        $("#ServiceNoticesDetailsDiv a[href^='#']").each(function (index, element) {
                            element.id = element.href.split('#')[1]; // element.id = anything after #
                        }).bind("click", function (eventObject) {
                            scrollToAnchorServiceNoticesDetails(eventObject.currentTarget.id); // element.id
                        }).removeAttr("href");


                        //console.log(data[i]);
                        break;
                    }
                }

            }).then(function () {                
                //DeepLink.getInstance().CatchClick({ contentElement: "#ServiceNoticesDetailsDiv", scrollTopElement: "#ServiceNoticesDetailsDiv", initialUrl: url, isPanelVisible: me.loadPanelVisible });
                me.loadPanelVisible(false);
            });


            //$.getJSON(url, function (data, textStatus, jqXHR) {
            //    id(data.Title);
            //    headline(data.Title);
            //    date(new Date(data.PostDateTime).toDateString());
            //    description(utils.fixContent(data.Content.toString()));
            //    var atl = utils.GetAppliesToLine(data.Taxonomies[0].Terms);
            //    appliesToLines(atl === null ? '' : '<strong>Applies to Line(s):</b> ' + atl);
            //    $("#ServiceNoticesDetailsDiv a[href^='#']").each(function (index, element) {
            //        element.id = element.href.split('#')[1]; // element.id = anything after #
            //    }).bind("click", function (eventObject) {
            //        scrollToAnchorServiceNoticesDetails(eventObject.currentTarget.id); // element.id
            //    }).removeAttr("href");
            //    DeepLink.getInstance().CatchClick({ contentElement: "#ServiceNoticesDetailsDiv", scrollTopElement: "#ServiceNoticesDetailsDiv", initialUrl: url, isPanelVisible: loadPanelVisible });
            //    self.loadPanelVisible(false);
            //    scrollToTopServiceNoticesDetails();
            //});
        }
    };
    return new viewModel();
};
